<template>
  <wx-form-container class="wizard">
    <div class="d-flex justify-center mt-10" v-if="isFetchingAlert">
      <v-progress-circular color="primary" indeterminate />
    </div>
    <v-form v-else ref="alertForm" lazy-validation>
      <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.kpiSectionTitle") }}</h5>
      <wx-autocomplete v-model="kpi" label="KPI" :items="kpiItems" :rules="kpiRules" />
      <!--   CURRENT DOWNTIME KPI FIELDS   -->
      <div v-if="kpi === 'UnplannedCurrentDowntime'">
        <v-row>
          <v-col>
            <wx-autocomplete v-model="operator" :label="$t('alerts.wizard.operator')" :items="operatorItems" disabled />
          </v-col>
          <v-col>
            <wx-timer-duration-input
              v-model="consecutiveTime"
              :title="$t('alerts.wizard.consecutiveTime')"
              :error-messages="[]"
              :rules="consecutiveTimeRules"
            />
          </v-col>
        </v-row>
      </div>
      <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.productionUnitSectionTitle") }}</h5>
      <wx-production-unit-multi-selector v-model="productionUnits" :available-production-units="factoryProductionUnits" :is-multiple-factories="false" />
      <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.recipientSectionTitle") }}</h5>
      <v-combobox
        v-model="recipients"
        :filter="filterRecipient"
        :hide-no-data="!recipientSearch"
        :items="recipientItems"
        :search-input.sync="recipientSearch"
        :error-messages="emailErrorMessages"
        :rules="recipientRules"
        :label="$t('alerts.wizard.emails')"
        hide-selected
        multiple
        small-chips
        filled
        clearable
        dense
        @change="onEmailChange"
      >
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-bind="attrs" color="primary" :input-value="selected" label small class="my-1">
            <span class="pr-2">
              {{ item }}
            </span>
            <v-icon small @click="parent.selectItem(item)">
              $delete
            </v-icon>
          </v-chip>
        </template>
      </v-combobox>
      <fragment v-if="alert">
        <h5 class="font-weight-light mb-2">{{ $t("alerts.wizard.alertStatus") }}</h5>
        <v-switch v-model="enabled" :label="alertStatus" color="primary" hide-details inset />
      </fragment>

      <form-footer-actions :click-submit="submit" cancel-to="toAlerts" />
    </v-form>
  </wx-form-container>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete.vue";
import WxTimerDurationInput from "@/components/ui/WxTimerDurationInput.vue";
import WxFormContainer from "@/components/ui/WxFormContainer.vue";
import { mapActions, mapGetters } from "vuex";
import FormFooterActions from "@/components/ui/FormFooterActions.vue";
import WxProductionUnitMultiSelector from "@/components/ui/WxProductionUnitMultiSelector.vue";
import AlertService from "@/components/alerts/AlertService";
import RouteService from "@/router/RouteService";
import validations from "@/components/validations";

export default {
  name: "AlertWizard",
  components: {
    WxProductionUnitMultiSelector,
    FormFooterActions,
    WxFormContainer,
    WxTimerDurationInput,
    WxAutocomplete,
  },
  props: {
    alert: {
      type: Object,
      default: () => null,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFetchingAlert: false,
      kpi: null,
      kpiRules: [(v) => !!v || "A KPI must be selected."],
      productionUnits: [],
      enabled: true,
      // CURRENT DOWNTIME KPI FIELD VALUES
      consecutiveTime: "00:15",
      operator: "greater_than_or_equal",
      // EMAIL RECIPIENTS DATA
      recipients: [],
      recipientItems: [],
      recipientSearch: null,
      emailErrorMessages: [],
      recipientRules: [
        (v) => !!v || "At least one email must be selected.",
        (v) => v.length > 0 || "At least one email must be selected.",
      ],
    };
  },
  watch: {
    recipients(val, prev) {
      if (val.length === prev.length) return;

      this.recipients = val.map((v) => {
        this.recipientItems.push(v);
        return v;
      });
    },
    userEmails() {
      this.recipientItems = this.recipients.concat(this.userEmails);
    },
    activeFactory() {
      this.initializeData();
    },
  },
  computed: {
    ...mapGetters("navigation", ["factoryUsers", "activeFactory"]),
    ...mapGetters("user", ["email"]),
    alertStatus() {
      return this.enabled ? this.$t("user.statusActive") : this.$t("user.statusInactive");
    },
    kpiItems() {
      return [
        {
          text: this.$t("alerts.types.currentDowntime"),
          value: "UnplannedCurrentDowntime",
        },
      ];
    },
    operatorItems() {
      return [{ text: this.$t("alerts.operators.above"), value: "greater_than_or_equal" }];
    },
    userEmails() {
      return this.factoryUsers.map((u) => u.email);
    },
    factoryProductionUnits() {
      return this.activeFactory ? this.activeFactory.productionUnits : [];
    },
    consecutiveTimeRules() {
      return [
        () => !!this.consecutiveTimeToMinutes || "Invalid input for consecutive minutes",
        () => this.consecutiveTimeToMinutes >= 5 || "A minimum of 5 consecutive minutes is required",
        () => this.consecutiveTimeToMinutes <= 1440 || "Cannot be more than 24 consecutive hours",
      ];
    },
    consecutiveTimeToMinutes() {
      if (!this.consecutiveTime || this.consecutiveTime === "") return null;
      try {
        const destructuredTime = this.consecutiveTime.split(":");
        const pattern = /\d{2}/;
        if (!destructuredTime[0]?.match(pattern) || !destructuredTime[1].match(pattern)) return null;
        const hours = parseInt(destructuredTime[0]);
        const minutes = parseInt(destructuredTime[1]);
        return hours * 60 + minutes;
      } catch (_err) {
        return null;
      }
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "handleHttpError"]),
    initializeData() {
      if (!this.activeFactory) return;
      if (this.alert) {
        this.recipients = this.alert.recipients;
        this.recipientItems = this.recipients.concat(this.userEmails);
        this.productionUnits = this.alert.production_unit_ids.map((puId) =>
          this.factoryProductionUnits.find((pu) => pu.id === puId),
        );
        this.enabled = this.alert.enabled;
        this.initializeKpiSelection();
      } else {
        this.recipientItems = this.recipients.concat(this.userEmails);
        this.productionUnits = this.factoryProductionUnits;
        this.recipients.push(this.email);
      }
    },
    initializeKpiSelection() {
      if (!this.alert) return;
      switch (this.alert.type) {
        case "UnplannedCurrentDowntime":
          this.initializeUnplannedCurrentDowntime();
          break;
      }
    },
    initializeUnplannedCurrentDowntime() {
      this.kpi = this.alert.type;
      let durationMinutes = this.alert.unplanned_current_downtime_rules.duration_minutes;
      let hours = parseInt(durationMinutes / 60)
        .toString()
        .padStart(2, "0");
      let minutes = parseInt(durationMinutes % 60)
        .toString()
        .padStart(2, "0");
      this.consecutiveTime = `${hours}:${minutes}`;
    },
    submit() {
      if (!this.activeFactory) return;
      if (!this.$refs.alertForm.validate()) return;
      let payload;
      switch (this.kpi) {
        case "UnplannedCurrentDowntime":
          payload = this.getDowntimeDurationPayload();
          break;
        default:
          return;
      }

      if (!this.alert) {
        // create the new alert
        this.createAlert(payload);
      } else {
        // update the existing alert
        this.updateAlert(payload);
      }
    },
    updateAlert(payload) {
      AlertService.updateAlert(this.activeFactory.id, this.alert.id, payload)
        .then(() => {
          this.showOperationSuccess(this.$t("alerts.successfulAlertUpdateMessage"));
          this.$router.replace(RouteService.toAlerts());
        })
        .catch((httpError) => {
          this.handleHttpError(httpError);
          this.$router.replace(RouteService.toAlerts());
        });
    },
    createAlert(payload) {
      AlertService.createAlert(this.activeFactory.id, payload)
        .then(() => {
          this.showOperationSuccess(this.$t("alerts.successfulAlertCreationMessage"));
          this.$router.replace(RouteService.toAlerts());
        })
        .catch((httpError) => {
          this.handleHttpError(httpError);
          this.$router.replace(RouteService.toAlerts());
        });
    },
    getDowntimeDurationPayload() {
      return {
        type: this.kpi,
        rules: {
          duration_minutes: this.consecutiveTimeToMinutes,
        },
        recipients: this.recipients,
        production_unit_ids: this.productionUnits.map((pu) => pu.id),
        enabled: this.enabled
      };
    },
    onEmailChange(value) {
      if (!value || value.length === 0) return;
      const lastEmailEntered = value[value.length - 1];
      if (validations.isUserEmailValid(lastEmailEntered) && this.emailErrorMessages.length > 0) {
        this.emailErrorMessages = [];
      } else if (!validations.isUserEmailValid(lastEmailEntered)) {
        this.emailErrorMessages = ["Invalid email address."];
        this.recipients.splice(value.length - 1, 1);
      }
    },
    filterRecipient(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
  },
  mounted() {
    this.initializeData();
  },
};
</script>

<style lang="scss" scoped>

</style>
